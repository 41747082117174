import classNames from 'classnames';
import styles from './beneficiaries-card.module.scss';
import CardEditButton from '../card-edit-button/card-edit-button';
import Input from '../input/input';
import Button from '../button/button';
import Card from '../card/card';
import { JSX, useEffect, useState } from 'react';
import { Beneficiary } from '../../types/beneficiary.type';
import { Plan } from '../../types/plan.type';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';
import CloseIcon from '../../icons/close.icon';
import NidInput from '../nid-input/nid-input';
import Link from '../link/link';
import { goToWhatsapp } from '../../utils/whatsapp.util';
import { User } from '../../types/user.type';
const ATC_NUMBER = process.env.REACT_APP_ATC_NUMBER;

interface Props {
    plan?: Plan;
    knownUser?: User;
    currentStep: number;
    shown: boolean;
    rollUp: boolean;
    submitBeneficiaries: (beneficiaries: Beneficiary[]) => void;
    goBack: () => void;
}

const BeneficiariesCard = (props: Props) => {
    const [beneficiaries, setBeneficiaries] = useState<Beneficiary[]>([
        {
            id: uuidv4(),
            fullName: '',
            nid: '',
            email: '',
            phone: '',
        },
    ]);
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [hasDuplicated, setHasDuplicated] = useState(false);
    const [showQuestion, setShowQuestion] = useState(false);
    const [showForm, setShowForm] = useState(false);

    useEffect(() => {
        if (props.plan?.serviceCode !== 'pana_family') {
            setBeneficiaries((prev) => prev.slice(0, 1));
        }
    }, [props.plan]);

    useEffect(() => {
        beneficiaries.length > 0 && validateFields();
    }, [beneficiaries]);

    useEffect(() => {
        if (props.plan?.serviceCode !== 'pana_family' && !!props.knownUser) {
            setShowQuestion(true);
            setShowForm(false);
        } else {
            setShowForm(true);
        }
    }, [props.plan, props.knownUser]);

    const resetBeneficiaries = () => {
        setBeneficiaries([
            {
                id: uuidv4(),
                fullName: '',
                nid: '',
                email: '',
                phone: '',
            },
        ]);
    };

    const validateFields = async () => {
        setHasDuplicated(false);
        const schema = yup.object({
            fullName: yup.string().min(7).required(),
            nid: yup.string().min(5).required(),
            email: yup.string().email().required(),
            phone: yup.string().min(7).required(),
        });
        for (const beneficiary of beneficiaries) {
            const isValid = await schema.isValid(beneficiary);
            if (!isValid) return setButtonDisabled(true);
        }
        setButtonDisabled(false);
    };

    const setBeneficiaryField = (id: string, field: string, value: string) => {
        const beneficiariesCopy = Array.from(beneficiaries);
        for (const beneficiary of beneficiariesCopy) {
            if (beneficiary.id === id) {
                (beneficiary as any)[field] = value.trim();
            }
        }
        setBeneficiaries(beneficiariesCopy);
    };

    const addBeneficiary = () => {
        if (beneficiaries.length >= 4) return;
        setBeneficiaries((prev) => [
            ...prev,
            {
                id: uuidv4(),
                fullName: '',
                nid: '',
                email: '',
                phone: '',
            },
        ]);
    };

    const removeBeneficiary = (id: string) => {
        let beneficiariesCopy = Array.from(beneficiaries);
        beneficiariesCopy = beneficiariesCopy.filter((b) => b.id !== id);
        setBeneficiaries(beneficiariesCopy);
    };

    const submitBeneficiaries = () => {
        if (checkHasDuplicated()) return;
        props.submitBeneficiaries(beneficiaries);
    };

    const checkHasDuplicated = (): boolean => {
        if (beneficiaries.length < 2) {
            setHasDuplicated(false);
            return false;
        }

        for (let i = 0; i < beneficiaries.length; i++) {
            const { nid, email, phone } = beneficiaries[i];
            for (let j = i + 1; j < beneficiaries.length; j++) {
                if (
                    beneficiaries[j].email === email ||
                    beneficiaries[j].phone === phone ||
                    beneficiaries[j].nid === nid
                ) {
                    setHasDuplicated(true);
                    return true;
                }
            }
        }
        setHasDuplicated(false);
        return false;
    };

    const autoFillWithKnownUser = () => {
        const { knownUser } = props;
        if (!knownUser) return;
        props.submitBeneficiaries([
            {
                id: knownUser.id.toString(),
                fullName: `${knownUser.firstName} ${knownUser.lastName}`,
                nid: `${knownUser.documentType}-${knownUser.document}`,
                email: knownUser.email,
                phone: knownUser.phoneNumber,
            },
        ]);
    };

    const renderOwnerQuestion = (): JSX.Element => {
        return (
            <>
                <span
                    className={classNames({
                        [styles.cardTitle]: true,
                        [styles.active]: props.shown,
                    })}
                >
                    ¿La suscripción es para ti o para otra persona?
                </span>
                <div className={styles.knownUserSelectionButtons}>
                    <button
                        className={styles.primary}
                        onClick={autoFillWithKnownUser}
                    >
                        Para mí
                    </button>
                    <button
                        className={styles.secondary}
                        onClick={() => {
                            resetBeneficiaries();
                            setShowForm(true);
                            setShowQuestion(false);
                        }}
                    >
                        Para otra persona
                    </button>
                </div>
            </>
        );
    };

    const renderFamilyWarning = (serviceCode?: string) => {
        if (!serviceCode || serviceCode !== 'pana_family') return <></>;
        return (
            <span className={styles.familyWarning}>
                <br />
                Por favor revisa que las personas que añadas no tengan una
                suscripción activa en curso
            </span>
        );
    };

    const renderBeneficiariesForm = () => {
        return (
            <>
                <span
                    className={classNames({
                        [styles.cardTitle]: true,
                        [styles.active]: props.shown,
                    })}
                >
                    Dinos los datos del beneficiario de la suscripción:
                </span>
                {renderFamilyWarning(props.plan?.serviceCode)}

                {beneficiaries.map((beneficiary, idx) => (
                    <form key={idx} className={styles.beneficiaryForm}>
                        {idx !== 0 && (
                            <div className={styles.formTitle}>
                                <span>Beneficiario {idx + 1}</span>
                            </div>
                        )}
                        <Input
                            placeholder={'Nombre y Apellido'}
                            onInput={(value) =>
                                setBeneficiaryField(
                                    beneficiary.id,
                                    'fullName',
                                    value
                                )
                            }
                        />
                        <NidInput
                            placeholder={'Cédula o Pasaporte'}
                            onInput={(value) =>
                                setBeneficiaryField(
                                    beneficiary.id,
                                    'nid',
                                    value.replaceAll('.', '')
                                )
                            }
                        />
                        <Input
                            placeholder={'Correo electrónico'}
                            onInput={(value) =>
                                setBeneficiaryField(
                                    beneficiary.id,
                                    'email',
                                    value
                                )
                            }
                        />
                        <Input
                            placeholder={'Teléfono celular'}
                            onInput={(value) =>
                                setBeneficiaryField(
                                    beneficiary.id,
                                    'phone',
                                    value
                                )
                            }
                        />
                        {idx !== 0 && (
                            <span
                                className={styles.errorButton}
                                onClick={() =>
                                    removeBeneficiary(beneficiary.id)
                                }
                            >
                                <CloseIcon color={'#fc7b7b'} />
                                Eliminar beneficiario
                            </span>
                        )}
                    </form>
                ))}
                {props.plan?.serviceCode === 'pana_family' &&
                    beneficiaries.length < 4 && (
                        <span
                            className={styles.addBeneficiaryForm}
                            onClick={addBeneficiary}
                        >
                            + Añadir otro beneficiario
                        </span>
                    )}
                {beneficiaries.length >= 4 && (
                    <div className={'column align-center'}>
                        <span>¿Quieres añadir más personas?</span>
                        <Link
                            text={'Contáctanos'}
                            blank={true}
                            goTo={goToWhatsapp(ATC_NUMBER!)}
                        />
                    </div>
                )}
                {hasDuplicated && (
                    <div className={styles.duplicationModal}>
                        <div className={styles.modal}>
                            <span className={styles.duplicationError}>
                                Hay beneficiarios duplicados. Por favor revisa
                                los correos, números de teléfono y los
                                documentos de identidad.
                            </span>
                            <span
                                className={styles.errorButton}
                                onClick={() => setHasDuplicated(false)}
                            >
                                Cerrar
                            </span>
                        </div>
                    </div>
                )}
                <Button
                    text={'Continuar'}
                    disabled={buttonDisabled}
                    onClick={submitBeneficiaries}
                />
            </>
        );
    };

    return (
        <Card
            className={classNames({
                [styles.card]: true,
                [styles.shown]: props.shown,
                [styles.rolledUp]: props.rollUp,
                [styles.formCard]: true,
            })}
        >
            <CardEditButton shown={props.rollUp} onClick={props.goBack} />
            {showQuestion && renderOwnerQuestion()}
            {showForm && renderBeneficiariesForm()}
        </Card>
    );
};

export default BeneficiariesCard;
